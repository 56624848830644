let scroll = 0;
let body = document.querySelector('body');
let buttons = document.querySelector('.c-interaction__buttons');
let logo = document.querySelector('.c-interaction__logo');
let nav = document.querySelector('.c-nav');
let status = 0;

if (status = 1) {
  window.addEventListener('scroll', function () {
    if (window.matchMedia('(max-width: 768px)').matches) {
      scroll += 3;
      if (window.pageYOffset > logo.offsetHeight) {
        buttons.classList.add('is-fixed')
        nav.style.marginTop = '45px'
      } else {
        buttons.classList.remove('is-fixed')
        nav.style.marginTop = '0'
      }
    }
    status = 0;
  })
}
window.addEventListener('resize', function() {
  status = 0
})
